var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'users.list'},"icon-left":"arrow-left"}}),( ! _vm.user)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm.user.name)+" ")])]],2)])]),(_vm.user)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveUser)}}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"firstname","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Firstname","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"lastname","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Lastname","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"country_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Country","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-country',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.country),callback:function ($$v) {_vm.$set(_vm.user, "country", $$v)},expression:"user.country"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Account ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"username","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Username","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"new_password","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Password","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"password","disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"new_password_confirmation","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Password confirmation","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"password","disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.new_password_confirmation),callback:function ($$v) {_vm.new_password_confirmation=$$v},expression:"new_password_confirmation"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Contact ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"email","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"E-mail","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"phone","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Phone","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1)]}}],null,true)})],1),_c('b-field',{attrs:{"horizontal":""}},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"save","disabled":! valid || _vm.isLoading},on:{"click":function($event){return _vm.saveUser()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}],null,false,959405123)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }