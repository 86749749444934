



































































































































































































































import Base from '@/mixins/Base.vue';
import { IUser, IUserResponse } from '@/interfaces/user';

const component = Base.extend({

    data() {
        return {

            user: undefined as any | IUser,
            new_password: '' as string,
            new_password_confirmation: '' as string,
        };
    },
    mounted() {
        this.getUser();
    },
    methods: {
        getUser(): void{
            this.get<IUserResponse>('auth/me', {
                params: this.$route.query,
            }).then(({ data }) => {
                this.user = data.data;
            });
        },
        saveUser(): void {
            this.put<IUserResponse>('auth/me', {
                ...this.user,
                generic_role: this.user.generic_role ? this.user.generic_role.name : null,
                new_password: this.new_password,
                new_password_confirmation: this.new_password_confirmation,
                country_id: this.user.country ? this.user.country.id : null,
            }).then(({ data }) => {
                this.$store.dispatch('getUser', data.data);
                this.$emit('success', { message: 'user updated' });
            });
        },

    },
});

export default component;
